<template>
  <div class="container">
    <!-- 面包屑 -->
    <!-- <BaseBread>
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>生态合作</BaseBreadItem>
    </BaseBread> -->
    <img src="@\assets\images\cooperation\coBanner.png" alt="">
     <!--文本分界线 begin-->
    <!-- <div class="text-split-line">
      <div class="info">
        <p class="title">高校合作</p>
        <hr width="100%">
        <p>
          博赛物联网开发套件是针对高校物联网教学打造的一套综合开发实训箱，具有灵活性、全面性、先进性、可扩展性等特点。满足高校物联网教学实训开发需求。        
        </p>
      </div>
    </div> -->
    <coInfoHarmony></coInfoHarmony>
    <coInfo></coInfo>
    <!-- <coInfoLab></coInfoLab>
    <coInfoComputer></coInfoComputer>
    <coInfoChip></coInfoChip> -->
    
    <!--文本分界线 begin-->
    <!-- <div class="text-split-line">
      <div class="info">
        <p>60+行业覆盖，3000+精品模板，随心DIY</p>
        <p>不用懂代码，选择模板，PPT式拖拽编辑，快速搭建网站</p>
      </div>
    </div> -->
    <!-- <AdvList></AdvList>
    <AdvVideo></AdvVideo> -->
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import useStore from "@/store";
import { findAd } from '@/api/home'
import { ref } from 'vue'
import coInfo from "./components/co-info-school.vue";
import AdvList from "./components/adv-list.vue";
import AdvVideo from "./components/adv-video.vue";
import coInfoHarmony from "./components/co-info-harmony.vue";
import coInfoLab from "./components/co-info-lab.vue";
import coInfoChip from "./components/co-info-chip.vue";
import coInfoComputer from "./components/co-info-computer.vue";
// // 获取当前路由对象
// const route = useRoute();
// // 获取动态路由参数的name
// const name = route.query.name;

export default {
  name: 'HomeBanner',
  components: { 
    coInfo ,AdvList,AdvVideo,coInfoHarmony,coInfoLab,coInfoChip,coInfoComputer
  },
  setup () {
    const dataSource = ref([])
    const dataSource2 = ref([])
    findAd("techplatImage").then(data => {
      dataSource.value = data.data.list[0]
    })
    findAd("techplatImage2").then(data => {
      dataSource2.value = data.data.list[0]
    })
    return { dataSource,dataSource2 }
  }
}

</script>

<style scoped lang="less">
.container{
  background-color: #fff;
}
.text-split-line {
  background: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  height: 160px;
  padding: 0 80px;
  .info {
    padding-left: 10px;
    flex: 1;
    p {
      text-align: left;
      &:first-child {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 18px;
      }
      &:last-child {
        color: #999;
        font-size: 16px;
      }
    }
  }
}
</style>
