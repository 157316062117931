<template>
  <div class="text-split-line">
      <div class="info">
        <p class="title">芯片</p>
        <!-- <p>
          博赛物联网开发套件是针对高校物联网教学打造的一套综合开发实训箱，具有灵活性、全面性、先进性、可扩展性等特点。满足高校物联网教学实训开发需求。        
        </p> -->
      </div>
    </div>
    <hr width="100%" >
  <div class="home-product" ref="target">
      <div class="box">
        <ul class="productadv-list">
          <li
            v-for="productadvInfo in productadvList"
            :key="productadvInfo.id"
          >
            <div class="productadv-item">
              <!-- <RouterLink to="/" class="image"> -->
                <div class="image">
                  <div class="point_top">
                    <img src="sddx.PNG" alt="">
                  </div>
                </div>
                <!-- <div class="point_top"> -->
                  <!-- <img src="sddx.PNG" alt=""> -->
                  <!-- <p> -->
                    <!-- {{ productadvInfo.name }}<br />{{ productadvInfo.summary }} -->
                  <!-- </p> -->
                <!-- </div> -->
              <!-- </RouterLink> -->
              <p class="name ellipsis-2">{{ productadvInfo.desc }}</p>
            </div>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { findBrand } from '@/api/home'
import { useLazyData } from '@/hooks'
export default {
  name: 'HomeBrand',
  components: {  },
  setup () {
     const { target, result } = {
    "target": "操作成功",
    "result": [
            {
                "id": "3995139",
                "name": "Education",
                "summary": "教育",
                "desc": "山东大学",
            },
            {
                "id": "3995139",
                "name": "DelveInto",
                "summary": "钻研",
                "desc": "学校名称",
            },
            {
                "id": "3995139",
                "name": "Major",
                "summary": "专业",
                "desc": "学校名称",
            },
            {
                "id": "3995139",
                "name": "Power",
                "summary": "力量",
                "desc": "规划和协调技术",
            },
            {
                "id": "3995139",
                "name": "Scientific",
                "summary": "科研",
                "desc": "以更全面的方式",
            },
            {
                "id": "3995139",
                "name": "Scientific",
                "summary": "科研",
                "desc": "以更全面的方式",
            },
            {
                "id": "3995139",
                "name": "Scientific",
                "summary": "科研",
                "desc": "以更全面的方式",
            },
            {
                "id": "3995139",
                "name": "Scientific",
                "summary": "科研",
                "desc": "以更全面的方式",
            }
            
        ]
}
    return { productadvList: result, target }
  }
}
</script>
<style scoped lang="less">
.text-split-line {
  //修改水平线以上样式
  background: #fff;
  display: flex;
  align-items: flex-end;
  text-align: center;
  height: 90px;
  padding: 0 50px;
  .info {
    padding-left: 1px;
    flex: 1;
    .title{
      font-size: 32px;
    }
    p {
      text-align: left;
      &:first-child {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 18px;
      }
      &:last-child {
        //修改title文字样式
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 18px;
        // color: #999;
        // font-size: 32px;
      }
    }
  }
}
.home-product {
  background: #fff;
  // height: 2900px;

  .box {
    display: flex;
    .cover {
      width: 240px;
      height: 610px;
      margin-right: 10px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .label {
        width: 188px;
        height: 66px;
        display: flex;
        font-size: 18px;
        color: #fff;
        line-height: 66px;
        font-weight: normal;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        span {
          text-align: center;
          &:first-child {
            width: 76px;
            background: rgba(0, 0, 0, 0.9);
          }
          &:last-child {
            flex: 1;
            background: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
    .productadv-list {
      width: 1240px;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 240px;
        height: 200px;//修改高度
        margin-right: 0px;
        margin-bottom: 0px;
        text-align: center; //文字居中
        &:nth-last-child(-n + 4) {
          margin-bottom: 0;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}
.productadv-item {
  width: 240px;
  height: 300px;
  padding: 10px 30px;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  transition: all 0.5s;
  .image {
    display: flex;
    //调节图片盒子宽度高度
    width: 180px;
    height: 110px;
    align-items: center;
    justify-content: center;
    margin: 0px 0px;
    // align-content: center;
    .point_top {
      //调整圆形盒子尺寸和半径，清除背景色
      width: 96px;
      height: 96px;
      border-radius: 48px;
      // background: #0075c2;
      cursor: pointer;
      transition: background 1.5s;
      -webkit-transition: all 0.8s ease-in-out;
      -moz-transition: all 0.8s ease-in-out;
      -o-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out;
      -ms-transition: all 0.8s ease-in-out;
      img{
        //调节图片尺寸半径
        width: 96px;
        height: 96px;
        border-radius: 48px;
      }
      p {
        color: #fff;
        line-height: 25px;
        font-size: 16px;
        padding-top: 45px;
        text-align: center;
      }
    }
    .point_top:hover {
      background: #2f383d;
      transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -webkit-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out;
      -ms-transition: all 0.8s ease-in-out;
    }
  }
  p {
    margin-top: 0px;
    font-size: 18px;
    &.name {
      // text-align: center;
      height: 44px;
    }
    &.desc {
      color: #666;
      height: 22px;
    }
    &.enterprise {
      margin-top: 10px;
      font-size: 20px;
      color: #0075c2;
    }
  }
  &:hover {
    //清除背景颜色
    // border-color: #0075c2;
    .extra {
      transform: none;
    }
  }
}
.productadv-image {
  width: 480px;
  height: 400px;
  position: relative;
  display: flex;
}
</style>
